import {createAsyncThunk, createDraftSafeSelector, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {AdministratorAssociationDTO, AdministratorDTO, administratorsApi} from '../apis/administrators-api'
import {RootState} from "../store/store";
import {AssociationDTO, associationsApi} from "../apis/associations-api";
import {TeamDTO, teamsApi} from "../apis/teams-api";
import auth from "../auth/auth";
import {configurationApi, ConfigurationDTO} from "../apis/configuration-api";
import {LocalStorageUtils} from "../utils/LocalStorageUtils";
import {ImageUtils} from '../utils/ImageUtils';
import {LocalizationDTO, localizationsApi} from "../apis/localizations-api";
import {LocalizationUtils} from "../utils/LocalizationUtils";
import {BrowserUtils} from "../utils/BrowserUtils";

interface SettingsState {
  // Title of current page displayed in application header
  pageTitle: string,
  // Information about currently logged-in user
  user: AdministratorDTO | null,
  // Is current user logged in
  isLoggedIn: boolean,
  // Is the application being initialized
  isInitializing: boolean,
  // Is application initialized
  isInitialized: boolean
  // Is main navigation minimized
  isMenuMinimized: boolean
  // Is user menu minimized
  isUserMenuMinimized: boolean
  // Associations current user belongs to
  associations: AssociationDTO[],
  // Currently selected association
  selectedAssociation: AssociationDTO | null,
  // Teams belonging to currently selected association
  teams: TeamDTO[],
  // Currently selected team
  selectedTeam: TeamDTO | null,
  // Application configuration, since it does not change, it's loaded only once when app is initialized
  configuration: ConfigurationDTO | null,
  // Map containing teams per association
  teamsPerAssociation: Record<string, TeamDTO[]> | null
  // Is dialog queue empty
  dialogQueueEmpty: boolean
  // Array of localization resources from the backend
  localizations: LocalizationDTO[]
  // Current application route
  currentRoute: string
}

const initialState: SettingsState = {
  pageTitle: '',
  user: null,
  isLoggedIn: false,
  isInitializing: false,
  isInitialized: false,
  isMenuMinimized: true,
  isUserMenuMinimized: true,
  associations: [],
  selectedAssociation: null,
  teams: [],
  selectedTeam: null,
  configuration: null,
  teamsPerAssociation: null,
  dialogQueueEmpty: true,
  localizations: [],
  currentRoute: ''
}

function filterOnlyConfirmed(associations: AssociationDTO[], user: AdministratorDTO): AssociationDTO[] {
  let confirmedAssociationsIds: string[] = []

  if (user.associations?.length) {
    confirmedAssociationsIds = user.associations.filter((it) => it.status === 'CONFIRMED').map((it) => it.association.id)
  }

  return associations.filter((it) => confirmedAssociationsIds.includes(it.id))
}

function filterOnlyWithTeams(associations: AssociationDTO[], teamsPerAssociation: Record<string, TeamDTO[]>): AssociationDTO[] {
  return associations.filter((it) => teamsPerAssociation[it.id]?.length)
}

export const initApp = createAsyncThunk<{
  isLoggedIn: boolean,
  user: AdministratorDTO | null,
  configuration: ConfigurationDTO | null,
  associations: AssociationDTO[],
  selectedAssociation: AssociationDTO | null,
  teams: TeamDTO[],
  selectedTeam: TeamDTO | null,
  teamsPerAssociation: Record<string, TeamDTO[]> | null
  localizations: LocalizationDTO[]
}>(
  'settings/initApp',
  async (payload, _thunkApi) => {


    const isLoggedIn = auth.isLoggedIn()

    let user: AdministratorDTO | null = null
    let configuration: ConfigurationDTO | null = null
    let associations: AssociationDTO[] = []
    let selectedAssociation: AssociationDTO | null = null
    let teams: TeamDTO[] = []
    let selectedTeam: TeamDTO | null = null
    let teamsPerAssociation: Record<string, TeamDTO[]> | null = null
    let localizations: LocalizationDTO[] = []

    if (isLoggedIn) {

      user = await administratorsApi.getAdministratorMe()
      configuration = await configurationApi.getConfiguration()
      localizations = await localizationsApi.getAll()

      const pageSize = 100

      // Now we need to get associations of current user
      const associationsPage = await associationsApi.getAssociations(0, pageSize)

      if (associationsPage.totalItems > pageSize) {
        console.warn('Number of associations for current user is ' + associationsPage.totalItems + ' which exceeds the limit of ' + pageSize + '.')
      }

      associations = associationsPage.items
      associations = filterOnlyConfirmed(associations, user)

      // Load all teams for all associations
      teamsPerAssociation = {}

      const loadedTeamsPerAssociation = await Promise.all(associations.map(it => teamsApi.getTeams(0, 100, it.id)))

      for (let i = 0; i < associations.length; i++) {
        const association = associations[i]
        const teamsPage = loadedTeamsPerAssociation[i]

        teamsPerAssociation[association.id] = teamsPage.items
      }

      associations = filterOnlyWithTeams(associations, teamsPerAssociation)

      selectedAssociation = getSelectedAssociation(associations)

      // User might not have any associations assigned to him so we need to check
      if (selectedAssociation) {

        const teamsPage = await teamsApi.getTeams(0, pageSize, selectedAssociation.id)

        teams = teamsPage.items

        if (teamsPage.totalItems > pageSize) {
          console.warn('Number of teams for current association is ' + teamsPage.totalPages + ' which exceeds the limit of ' + pageSize + '.')
        }

        selectedTeam = getSelectedTeam(teams, selectedAssociation.id)
      }
    }

    // Update local storage settings
    LocalStorageUtils.saveAssociationSelection(selectedAssociation?.id)
    LocalStorageUtils.saveTeamSelection(selectedTeam?.id)

    return {
      isLoggedIn,
      user,
      configuration,
      associations,
      selectedAssociation,
      teams,
      selectedTeam,
      teamsPerAssociation,
      localizations
    }
  }
)

const getSelectedAssociation = (associations: AssociationDTO[]): AssociationDTO | null => {
  if (!associations.length)
    return null

  let selectedAssociation: AssociationDTO | null = null

  // Now we need to pick an association as currently selected

  // First check if local storage has this information already
  const preselectedAssociationId = LocalStorageUtils.getAssociationSelection()

  if (preselectedAssociationId) {
    // If the association is among the available ones, we use that one
    const association = associations.find(it => it.id === preselectedAssociationId)

    // Preselected association might not be available to the current user anymore
    if (association)
      selectedAssociation = association
  }

  // If we haven't selected association yet, just pick the first one
  if (!selectedAssociation) {
    selectedAssociation = associations[0]
  }

  return selectedAssociation
}

const getSelectedTeam = (teams: TeamDTO[], _associationId: string): TeamDTO | null => {

  if (!teams.length)
    return null

  let selectedTeam: TeamDTO | null = null

  // We need to pick a team as currently selected

  // First check local storage
  // Here we use associationId in the local storage key also so we can save a preferred team per association
  const preselectedTeamId = LocalStorageUtils.getTeamSelection()

  if (preselectedTeamId) {
    // If this team is among the available ones we use that one
    const team = teams.find(it => it.id === preselectedTeamId)

    // Preselected team might not be available anymore
    if (team) {
      selectedTeam = team
    }
  }

  // If we haven't selected a team yet, just pick the first one
  if (!selectedTeam) {
    selectedTeam = teams[0]
  }

  return selectedTeam
}

export const selectTeam = createAsyncThunk<void, { teamId: string, associationId: string }>(
  'settings/selectTeam',
  async (payload, thunkApi) => {

    const {teamId, associationId} = payload

    const rootState = thunkApi.getState() as RootState
    const associations = rootState.settings.associations
    const teamsPerAssociation = rootState.settings.teamsPerAssociation

    const associationToSelect = associations.find(it => it.id === associationId)!
    const teams = teamsPerAssociation ? teamsPerAssociation[associationId] : []
    const teamToSelect = teams.find(it => it.id === teamId)

    if (associationToSelect && teamToSelect) {

      LocalStorageUtils.saveTeamSelection(teamId)
      LocalStorageUtils.saveAssociationSelection(associationId)

      thunkApi.dispatch(setTeams(teams))
      thunkApi.dispatch(setSelectedTeam(teamToSelect))
      thunkApi.dispatch(setSelectedAssociation(associationToSelect))
    } else {
      console.warn('Could not find association and team to select:', payload)
    }
  }
)

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setTitle: (state, action) => {
      state.pageTitle = action.payload
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload
      // if (!action.payload) {
      //   state.user = {}
      // }
    },
    setUser: (state, action) => {
      state.user = action.payload
    },
    setMenuMinimized: (state, action) => {
      state.isMenuMinimized = action.payload
    },
    setUserMenuMinimized: (state, action) => {
      state.isUserMenuMinimized = action.payload
    },
    updateCurrentUser: (state, action: PayloadAction<Pick<AdministratorDTO, 'firstName' | 'lastName' | 'email' | 'phoneNumber'>>) => {
      state.user!.firstName = action.payload.firstName
      state.user!.lastName = action.payload.lastName
      state.user!.phoneNumber = action.payload.phoneNumber
      state.user!.email = action.payload.email
    },
    setSelectedTeam: (state, action) => {
      state.selectedTeam = action.payload
    },
    setSelectedAssociation: (state, action) => {
      state.selectedAssociation = action.payload
    },
    setTeams: (state, action: PayloadAction<TeamDTO[]>) => {
      state.teams = action.payload
    },
    setDialogQueueEmpty: (state, action: PayloadAction<boolean>) => {
      state.dialogQueueEmpty = action.payload
    },
    setConfirmedMarketing: (state, action: PayloadAction<{ administratorAssociationId: string, value: boolean }>) => {
      state.user!.associations!.forEach(it => {
        if (it.id === action.payload.administratorAssociationId)
          it.confirmedMarketing = action.payload.value
      })
    },
    setCurrentRoute: (state, action: PayloadAction<string>) => {
      state.currentRoute = action.payload
    }
  },
  extraReducers: (builder) => {

    // Init app
    builder.addCase(initApp.pending, (state) => {
      state.isInitializing = true
    })
    builder.addCase(initApp.fulfilled, (state, action) => {

      const payload = action.payload

      state.isLoggedIn = payload.isLoggedIn
      state.user = payload.user
      state.configuration = payload.configuration
      state.teams = payload.teams
      state.selectedTeam = payload.selectedTeam
      state.associations = payload.associations
      state.selectedAssociation = payload.selectedAssociation
      state.teamsPerAssociation = payload.teamsPerAssociation
      state.localizations = payload.localizations
      state.isInitializing = false
      state.isInitialized = true
    })
    builder.addCase(initApp.rejected, (state) => {
      state.isInitializing = false
      state.isInitialized = true
    })
  },
})

// Actions
export const {
  setTitle,
  setIsLoggedIn,
  setUser,
  setMenuMinimized,
  updateCurrentUser,
  setSelectedTeam,
  setSelectedAssociation,
  setTeams,
  setUserMenuMinimized,
  setConfirmedMarketing,
  setDialogQueueEmpty,
  setCurrentRoute
} = settingsSlice.actions

// Selectors
export const selectTitle = (state: RootState) => state.settings.pageTitle
export const selectIsLoggedIn = (state: RootState) => state.settings.isLoggedIn
export const selectIsInitializing = (state: RootState) => state.settings.isInitializing
export const selectIsInitialized = (state: RootState) => state.settings.isInitialized
export const selectUser = (state: RootState) => state.settings.user
export const selectConfiguration = (state: RootState) => state.settings.configuration
export const selectUserId = (state: RootState) => state.settings.user ? state.settings.user.id : undefined
export const selectIsMenuMinimized = (state: RootState) => state.settings.isMenuMinimized
export const selectIsUserMenuMinimized = (state: RootState) => state.settings.isUserMenuMinimized
export const selectSelectedAssociation = (state: RootState) => state.settings.selectedAssociation
export const selectSelectedTeam = (state: RootState) => state.settings.selectedTeam
export const selectAssociations = (state: RootState) => state.settings.associations
export const selectTeamsPerAssociation = (state: RootState) => state.settings.teamsPerAssociation
export const selectDialogQueueEmpty = (state: RootState) => state.settings.dialogQueueEmpty
export const selectLocalizations = (state: RootState) => state.settings.localizations
export const selectCurrentRoute = (state: RootState) => state.settings.currentRoute

export const selectSelectedAdministratorAssociation = createDraftSafeSelector(selectUser, selectSelectedAssociation, (user: AdministratorDTO | null, association: AssociationDTO | null): (AdministratorAssociationDTO | undefined) => {
  if (!user || !user.associations || !association) return

  return user.associations.find(it => it.association.id === association.id)
})

export const selectTotalTeams = createDraftSafeSelector(selectTeamsPerAssociation, (teamsPerAssociation: Record<string, TeamDTO[]> | null) => {
  return Object.keys(teamsPerAssociation || {}).map(associationId => teamsPerAssociation![associationId].length).reduce((prev, curr) => prev + curr, 0)
})

export const selectHasAtLeastOneTeam = createDraftSafeSelector(selectTotalTeams, (totalTeams: number) => {
  return totalTeams > 0
})

export const selectBackgroundUrl = createDraftSafeSelector(selectSelectedTeam, selectSelectedAssociation, (selectedTeam: TeamDTO | null, selectedAssociation: AssociationDTO | null) => {
  if (!selectedTeam || !selectedAssociation) {
    return ''
  }

  return ImageUtils.createTeamBackgroundUrl(selectedTeam, selectedAssociation)
})

export const selectTotalAssociationsWithTeams = createDraftSafeSelector(selectTeamsPerAssociation, (teamsPerAssociation: Record<string, TeamDTO[]> | null) => {
  return Object.keys(teamsPerAssociation || {})
    .map(associationId => teamsPerAssociation![associationId].length)
    .filter(it => it > 0).length
})

export const selectIsChairman = createDraftSafeSelector(selectUser, selectSelectedAssociation, (user: AdministratorDTO | null, selectedAssociation: AssociationDTO | null) => {
  if (!user || !user.associations || !selectedAssociation)
    return false

  const currentAdministratorAssociation = user.associations.find(it => it.association.id === selectedAssociation!.id)

  if (currentAdministratorAssociation && currentAdministratorAssociation.roles)
    return currentAdministratorAssociation.roles.includes('CHAIRMAN')

  return false
})

export const selectLocalizationsCache = createDraftSafeSelector(selectLocalizations, (localizations: LocalizationDTO[]) => {
  return LocalizationUtils.createCache(localizations)
})

/**
 * Frozen screen routes are those that display unnavigable pages i.e. pages that should not allow navigation to
 * any other page using any links.
 */
export const selectIsFrozenScreenRoute = createDraftSafeSelector(selectCurrentRoute, (route: string) => {
  const frozenScreenRoutes = ['/confirm-invitation', '/marketing-sales', '/marketing-digital-screens', '/marketing-win']

  return frozenScreenRoutes.filter(it => route && route.startsWith(it)).length > 0
})

/**
 * Full screen routes are those that display the application in a full screen mode i.e. the application should omit
 * everything except the content that should be visible in the app.
 * This allows browsers that don't support native full screen to behave as close as possible to it.
 */
export const selectIsFullScreenRoute = createDraftSafeSelector(selectCurrentRoute, (route: string) => {

  // Some pages should be displayed in full screen mode even if full screen is supported
  const alwaysFullScreenRoutes = ['/marketing-win']

  if(alwaysFullScreenRoutes.filter(it => route && route.startsWith(it)).length > 0)
    return true

  // Some pages should be displayed in full screen mode but only if native full screen mode is not available
  if(BrowserUtils.fullscreenSupported())
    return false

  const fullScreenRoutes = ['/marketing-sales', '/marketing-digital-screens']

  return fullScreenRoutes.filter(it => route && route.startsWith(it)).length > 0
})

export default settingsSlice.reducer
