const swedishTranslations = {
  translations: {
    menu: {
      title: 'Supportern Admin',
      start: 'Start',
      getStarted: 'Kom igång',
      allGames: 'Alla spel',
      createDraw: 'Skapa spel',
      statistics: 'Statistik',
      associations: 'Föreningen',
      settings: 'Inställningar',
      chairman: 'Ordförande',
      help: 'Hjälp',
      terms: 'Villkor & integritet',
      marketing: 'Marknadsföring'
    },
    userMenu: {
      signupButton: 'Bli administratör',
      loginButton: 'Logga in',
      logout: 'Logga ut'
    },
    pages: {
      acceptInvitation: {
        title: 'Acceptera inbjudan',
        rejectedSuccessfullyMessage: 'Du har tackat nej till inbjudan.',
        rejectDialog: {
          title: 'Vill du avböja att bli administratör?',
          confirmLabel: 'Ja, avböj',
          closeLabel: 'Avbryt'
        },
        successfulRejectDialog: {
          title: 'Du har avböjt att bli administratör',
          text: 'Har du ångrat dig går det alltid att bli administratör här på startsidan igen.',
          closeLabel: 'Ok'
        },
        actionBox: {
          title: 'Bli administratör',
          text: 'Du har blivit inbjuden av {{inviter}} att bli administratör för {{association}}.',
          confirmLabel: 'GODKÄNN',
          closeLabel: 'Avböj'
        },
        approveFailedDialog: {
          title: 'Godkännande fel'
        },
        errors: {
          tokenNotFound: 'Token hittades inte.',
          invitationNotFound: 'Inbjudan hittades inte.',
          invitationExpired: 'Inbjudan har löpt ut.',
          invitationNotValid: 'Inbjudan är inte giltig eller har redan använts.',
          loggedIn: 'För att kunna acceptera inbjudan, vänligen logga ut från din nuvarande session först.'
        },
        logout: 'Logga ut'
      },
      confirmInvitation: {
        title: 'Bekräfta inbjudan',
        account: {
          title: 'Godkänn uppgifter',
          subtitle: 'Kolla igenom att dina uppgifter stämmer och godkänn ditt konto.',
          associationTitle: 'Förening',
          tempDataNotification: 'En del av de uppgifter som du har blivit inbjuden med skiljer sig från de uppgifter vi redan har om ditt konto, så se till att kontrollera om den är korrekt.',
          personalDataTitle: 'Så behandlar vi personuppgifter',
          personalDataText: 'Här kan du läsa mer om du undrar hur vi behandlar personuppgifter.',
          personalDataLabel: 'PERSONUPPGIFTER',
          confirmLabel: 'Godkänn personuppgifter'
        },
        actionBox: {
          title: 'Bli administratör',
          text: 'Du har blivit inbjuden av {{inviter}} att bli administratör för {{association}}.',
          confirmLabel: 'GODKÄNN',
          closeLabel: 'Avböj'
        },
        errors: {
          tokenNotFound: 'Token hittades inte.',
          invitationNotFound: 'Inbjudan hittades inte.',
          invitationExpired: 'Inbjudan har löpt ut.',
          invitationNotValid: 'Inbjudan är inte giltig eller har redan använts.',
          loggedOut: 'För att acceptera inbjudan måste du vara inloggad.'
        },
      },
      association: {
        title: 'Föreningen',
        subtitle: 'Här ser du lagen du administrerar. Om du administrerar fler än ett lag kan du välja vilket du vill jobba med från rullmenyn.',
        activeDrawsLabel: '({{count}}) aktiva lotterier',
        selectTeamTitle: 'Aktivt lag',
        selectTeamLabel: 'Välj lag att administrera',
        associationInfo: 'Föreningens uppgifter'
      },
      draw: {
        header: {
          marketingLabel: 'Marknadsför'
        }
      },
      drawAdd: {
        title: 'Skapa spel',
        stepLabel: 'Steg',
        matchNumberLabel: 'Match {{number}}',
        startDateLabel: 'Startid',
        startDateRequiredMessage: 'Starttid måste anges',
        startDateMinMessage: 'Starttid måste vara efter {{min}}',
        startDateMaxMessage: 'Starttid måste vara före {{max}}',
        endDateLabel: 'Stopptid',
        endDateRequiredMessage: 'Stopptid måste anges',
        endDateMinMessage: 'Stopptid måste vara efter {{min}}',
        endDateMaxMessage: 'Stopptid måste vara före {{max}}',
        priceLabel: 'Pris',
        footerSingleTitle: 'Skapa singelspel',
        footerSingleEventTitle: 'Skapa eventspel',
        footerSingleMatchText: 'Gå tillbaka för att göra ändringar eller fortsätt för att skapa singelspelet. Det är möjligt att ändra eller ta bort kommande spel innan de har nått starttiden och aktiverats.',
        footerSingleEventText: 'Gå tillbaka för att göra ändringar eller fortsätt för att skapa eventspelet. Det är möjligt att ändra eller ta bort kommande spel innan de har nått starttiden och aktiverats.',
        footerMultiTitle: 'Skapa seriespel',
        footerMultiText: 'Gå tillbaka för att göra ändringar eller fortsätt för att skapa seriespelet. Det är möjligt att ändra eller ta bort kommande spel innan de har nått starttiden och aktiverats.',
        confirmationSingleTitle: 'Vill du skapa singelspel?',
        confirmationMultipleTitle: 'Vill du skapa seriespel?',
        confirmationSingleEventTitle: 'Vill du skapa eventspel?',
        confirmationSingleMatchText: 'Du har valt att skapa ett spel för {{drawName}} till ett styckpris av {{price}} per spel.\n\nSpelet startar {{startDate}} och avslutas {{endDate}}.\n\nInnan spelet startar, och därmed aktiveras, kan du ta bort det eller ändra start- och stopptid.\n\nDet krävs sex (6) sålda spel vid spelstopp för att dragningen ska kunna genomföras.',
        confirmationMultipleMatchText: 'Du har valt att skapa {{count}} spel där det första spelet aktiveras {{startDate}}. Det sista spelet avslutas {{endDate}}.\n\nInnan spelen startar, och därmed aktiveras, kan du ta bort dem eller ändra start- och stopptid.\n\nDet krävs sex (6) sålda spel vid spelstopp för att dragningen ska kunna genomföras.',
        confirmationSingleEventText: 'Du har valt att skapa ett eventspel för {{teamName}} till ett styckpris av {{price}} per spel. \n\nSpelet startar {{startDate}} och avslutas {{endDate}}.\n\nInnan spelet startar, och därmed aktiveras, kan du ta bort det eller ändra start- och stopptid.',
        reviewTitle: 'Granska',
        singleMatchReviewSubtitle: 'Nu är du snart igång. Granska dina inställningar så att allt stämmer. Sedan är det bara att skapa singelspelet och börja marknadsföra till lagets fans.',
        seriesMatchReviewSubtitle: 'Nu är du snart igång. Granska dina inställningar så att allt stämmer. Sedan är det bara att skapa seriespelet och börja marknadsföra till lagets fans.',
        eventReviewSubtitle: 'Nu är du snart igång. Granska dina inställningar så att allt stämmer. Sedan är det bara att skapa eventpelet och börja marknadsföra till lagets fans.',
        ticketPriceLabel: 'Pris per spel',
        ticketRecommendedPriceLabel: '({{price}} är rekommenderat)',
        cancel: 'Avbryt',
        activate: 'Skapa',
        create: 'Skapa',
        step1: {
          title: 'Skapa ett nytt spel',
          subtitle: 'Skapa ett spel för en hel säsong, en enskild match eller ett eget event.',
          selectTypeLabel: 'Välj typ av spel',
          typeMatch: 'Matchspel',
          typeEvent: 'Eventspel',
          footerMessage: 'Matchspel är kopplat till en specifik match, eller flera matcher i en serie. Ett eventspel kan pågå under en längre tid och lämpar sig därför bra för att samla in pengar till ett specifikt syfte under flera veckor eller månader.\n' +
            '\n' +
            'När du har skapat ett eller flera spel kan du hämta marknadsföringsmaterial på <link></link>.',
          footerMessageLink: 'marknadsföringssidan'
        },
        event: {
          step2: {
            title: 'Eventspel',
            subtitle1: 'Sätt en titel och en beskrivning på eventspelet så att era följare förstår syftet med insamlingen.',
            subtitle2: 'Ett event-spel är inte lagspecifikt utan samlar in pengar till hela föreningen. Du kan max ha fem (5) eventspel aktiva samtidigt.',
            formTitle: 'Titel och beskrivning',
            titleLabel: 'Titel',
            descriptionLabel: 'Beskrivning',
            titleRequiredMessage: 'Event-spelet måste ha en titel',
            titlePatternMessage: 'Specialtecken är ej tillåtna',
            titleMaxLengthMessage: 'Titeln får inte innehålla fler än {{count}} tecken',
            titleMinLengthMessage: 'Titeln måste ha minst {{count}} tecken',
            descriptionPatternMessage: 'Specialtecken är ej tillåtna',
            descriptionMaxLengthMessage: 'Beskrivningen får inte innehålla fler än {{count}} tecken',
            descriptionMinLengthMessage: 'Beskrivningen måste ha minst {{count}} tecken',
            badWordsDialog: {
              title: 'Förbjudna ord',
              text: 'Du använder för närvarande förbjudna ord för namnet eller beskrivningen av ditt event. Ändra uppgifterna och ta bort alla förbjudna ord.',
              errorTitle: 'Fel vid validering av data',
              errorText: 'Fel vid validering av angivna data. Var god försök igen.'
            }
          },
          step3: {
            title: 'Tid och pris',
            subtitle: 'Start- och stopptid syftar på när eventspelet på Supportern ska startas och avslutas. Starttiden kan sättas max ett år framåt och maxgränsen för stopptid är ytterligare ett år efter starttiden.',
          },
          step4: {
            cardTitle: 'Eventspel',
            confirmSuccessTitle: 'Ditt eventspel är skapat',
            confirmSuccessText: 'Spelet finns nu i din lista över kommande spel. När starttiden är inne blir det automatiskt aktiverat.'
          }
        },
        match: {
          seriesTitle_one: 'Serien innehåller {{count}} spel',
          seriesTitle_other: 'Serien innehåller {{count}} spel',
          seriesReviewTitle: 'Totalt {{count}} st spel i serien',
          edit: 'Ändra',
          retryDialogTitle: 'Försök igen',
          retryDialogText: 'Vill du försöka igen och skapa osparade dragningar?',
          step2: {
            title: 'Matchspel',
            multipleDrawTitle: 'Seriespel',
            subtitle: 'Välj motståndare och om det är hemma- eller bortamatch.',
            formTitle: '{{teamName}} vs.',
            opponentTeamLabel: 'Motståndare',
            opponentTeamRequiredMessage: 'Motståndare måste väljas',
            opponentTeamPlaceholder: 'Välj motståndare',
            homeLabel: 'Hemma',
            awayLabel: 'Borta',
            arenaLabel: 'Arena'
          },
          step3: {
            title: 'Tid och pris',
            subtitle: 'Start- och stopptid avser spelet på Supportern, inte matchen. Vi rekommenderar att ni väljer stopptid i anslutning till matchens slut.',
            footerMessage: 'Nästa spel i en serie startar direkt när detta spel avslutas.',
            addNewDrawLabel: 'LÄGG TILL NYTT SPEL',
            gameAddedTitle: 'Spel som lagts till',
            startDateInfo: 'Maxtid kan sättas 1 år från dagens datum på starttid.',
            endDateInfo: 'Stopptid kan max vara 1 år från starttiden.'
          },
          step4: {
            singleMatchCardTitle: 'Matchspel',
            multipleMatchCardTitle: 'Första spelet i din serie',
            confirmSuccessTitle: 'Ditt singelspel är skapat',
            confirmSuccessText: 'Spelet är skapat och finns nu i din lista över kommande spel. Spelet aktiveras automatiskt när det når sin starttid och avslutas automatiskt när det når sin stopptid.',
            confirmMultipleSuccessTitle: 'Ditt seriespel är skapat',
            confirmMultipleSuccessText: 'Dina spel är skapade och finns nu i din lista över kommande spel. Respektive spel aktiveras automatiskt när det når sin starttid och avslutas automatiskt när det når sin stopptid.',
            partialSaveDialogText_one: '\n\nEndast första dragningen har sparats.\n\nEfter att ha stängt denna popup, kommer du att ha ett alternativ att försöka skapa de misslyckade igen.',
            partialSaveDialogText_other: '\n\nEndast de första {{count}} dragningarna har sparats.\n\nEfter att ha stängt denna popup, kommer du att ha ett alternativ att försöka skapa de misslyckade igen.',
            showAll: 'Visa hela listan'
          }
        },
        drawTicket: {
          priceLabel: 'Pris'
        }
      },
      draws: {
        title: 'Alla spel',
        countLabel: {
          active: '{{count}} st pågående',
          pending: '{{count}} st kommande',
          closed: '{{count}} st avslutade',
          cancelled: '{{count}} st makulerade',
        },
        countLabelActiveMatch: '{{count}} st matchspel',
        countLabelActiveEvent: '{{count}} st eventspel',
        countLabelActiveEvent_other: '{{count}} st eventspel',
        loading: 'Hämtar...',
        create: {
          title: 'Skapa ett nytt spel',
          buttonLabel: 'Skapa spel'
        },
        tabs: {
          ALL: 'Alla',
          ACTIVE: 'Pågående',
          PENDING: 'Kommande',
          CLOSED: 'Avslutade',
          CANCELLED: 'Makulerade'
        },
        noDataMessage: {
          activeDraws: 'Du har inga pågående spel.',
          pendingDraws: 'Du har inga kommande spel.',
          closedDraws: 'Du har inga avslutade spel.',
          cancelledDraws: 'Du har inga makulerade spel.',
        }
      },
      home: {
        title: 'Hem',
        header: {
          createDrawLabel: 'Skapa spel',
          incomeLabel: 'Insamlat {{year}}',
          supportersLabel: 'Favoritmarkeringar',
          supportersLabelValue: '{{value}} st',
        },
        hero: {
          welcomeMessage: 'Välkommen {{name}}!',
          teamSwitcherLabel: 'Välj  förening och lag',
        },
        goToDrawsLabel: 'Gå till alla spel',
        administrators: {
          title: 'Administratörer',
          subtitle: 'En administratör för ett lag kan administrera lagets konto och skapa nya spel.'
        },
        pendingDraw: {
          title: 'Nästa dragning',
        },
        closedDraw: {
          title: 'Senast avslutade spelet',
          noDataMessage: 'Du har inga avslutade spel.'
        },
        matchDraw: {
          title: 'Pågående matchspel',
          noDataMessage: 'Du har inga pågående matchspel.'
        },
        eventDraw: {
          title: 'Pågående eventspel',
          noDataMessage: 'Du har inga pågående eventspel.'
        },
        teamSwitchInfoDialog: {
          title: 'Hej {{name}}!',
          text: `Du administrerar flera olika lag för {{association}}. Din startvy är nu <b>{{teamInfo}}</b>. Vill du byta till ett annat lag så gör det du via lagväljaren här på startsidan, eller via menyn "Föreningen".`,
          closeLabel: 'Ok'
        }
      },
      administrators: {
        title: 'Administratörer',
        subtitle: 'Lägg till, ändra uppgifter eller ta bort administratörer för ett eller flera lag.',
        activeTitle: 'Aktiva',
        pendingTitle: 'Väntande',
        inactiveTitle: 'Ej accepterade',
        associationLabel: 'Förening',
        phoneLabel: 'Telefone',
        emailLabel: 'E-post',
        statusLabel: 'Status',
        startLabel: 'Startade',
        requestLabel: 'Utskick',
        addLabel: 'Lägg till Administratör',
        changeLabel: 'Ändra',
        statusExpired: 'Inaktiv, ej svarat inom 48 tim',
        statusCancelled: 'Avböjt förfrågan',
        limitDialog: {
          title: 'Max antal administratörer',
          text: 'Det går bara att ha tre ({{limit}}) aktiva administratörer samtidigt.\nDu behöver ta bort en administratör för att kunna lägga till en ny.'
        },
      },
      administratorsAdd: {
        insertTitle: 'Lägg till administratör',
        insertSubtitle: 'Lägg till den nya administratörens person- och kontaktuppgifter samt vilket eller vilka lag personen ska administrera.',
        updateTitle: 'Ändra uppgifter',
        updateSubtitle: 'Lägg till, ta bort, eller ändra lag som personen ska administrera.',
        personalNumberLabel: 'Personnummer',
        personalNumberMessage: 'Ogiltigt Personnummer, bör vara \'ÅÅÅÅMMDDNNNN\'',
        personalNumberRequiredMessage: 'Personnummer krävs',
        firstNameLabel: 'Förnamn',
        firstNameRequiredMessage: 'Förnamn krävs',
        firstNameMinMessage: 'Förnamn måste innehålla minst {{count}} tecken',
        firstNameMaxMessage: 'Förnamnet får ha högst {{count}} tecken',
        lastNameLabel: 'Efternamn',
        lastNameRequiredMessage: 'Efternamn krävs',
        lastNameMinMessage: 'Efternamnet måste ha minst {{count}} tecken',
        lastNameMaxMessage: 'Efternamnet får ha högst {{count}} tecken',
        emailMaxMessage: 'E-post får ha högst {{count}} tecken',
        phoneLabel: 'Telefonnummer',
        phoneRequiredMessage: 'Telefonnummer krävs',
        phonePatternMessage: `Var god ange ett giltigt telefonnummer med 6 till 15 siffror. Endast siffror och tecknet '+' är tillåtna`,
        emailLabel: 'E-post',
        emailMessage: 'Måste vara en giltig e-postadress',
        insertFooterMessage: 'När du skickar inbjudan kommer administratören få en notifikation och har då 48 timmar på sig att acceptera förfrågan.',
        insertConfirmLabel: 'Skicka inbjudan',
        updateFooterMessage: 'När du sparar ändringarna kommer administratören få en notifikation.',
        updateBackLabel: 'Avbryt',
        updateConfirmLabel: 'Spara ändringar',
        uniquePropertyErrorMessage: 'Se till att personen du lägger till inte redan är tillagd.',
        administratorHeader: {
          confirmedMessage: 'Administratör sedan {{date}}.',
          pendingMessage: 'Förfrågan skickades {{date}}',
          canceledMessage: 'Avböjt {{date}}.',
          expiredMessage: 'Administratören har inte godkänt förfrågan inom tidsgränsen på 48 timmar.',
          chairmanLabel: 'Ordförande, {{association}}',
          adminLabel: 'Administratör, {{association}}'
        },
        resendNotificationLabel: 'Skicka ny sms-inbjudan',
        removeAdministratorLabel: 'Ta bort administratör',
        deleteDialog: {
          title: 'Ta bort administratören?',
          text: 'Vill du ta bort {{name}} som administratör? \n\n' +
            'Kopplingen mellan administratören och föreningen kommer tas bort och en ny anslutning- och verifieringsprocess måste genomföras för att lägga till administratören igen.',
          closeLabel: 'avbryt',
          confirmLabel: 'ta bort admin'
        },
        successfulDeletionDialog: {
          title: 'Administratören är nu borttagen!',
          confirmLabel: 'Ok'
        },
        saveChangesDialog: {
          title: 'Vill du spara dina ändringar?',
          closeLabel: 'Avbryt',
          confirmLabel: 'Spara ändring'
        },
        successfulSaveDialog: {
          title: 'Snyggt, dina ändringar är sparade!',
          confirmLabel: 'OK'
        },
        resendInvitationDialog: {
          title: 'Vill du skicka en ny SMS-inbjudan till {{name}}?',
          closeLabel: 'Avbryt',
          confirmLabel: 'Skicka inbjudan'
        },
        successfulInvitationDialog: {
          title: 'Stiligt, nu har {{name}} fått en ny SMS-inbjudan',
          confirmLabel: 'OK'
        },
      },
      myAccount: {
        title: 'Inställningar',
        chairmanLabel: 'Ordförande, {{association}}',
        adminLabel: 'Administratör, {{association}}',
        footerMessage: 'Har du ändrat dina kontaktuppgifter kommer vi att skicka en bekräftelse till din e-postadress för verifiering.',
        saveChangesDialog: {
          title: 'Vill du spara dina ändringar?',
          closeLabel: 'Avbryt',
          confirmLabel: 'Spara ändring'
        },
        successfulSaveDialog: {
          title: 'Snyggt, dina ändringar är sparade!',
          closeLabel: 'Ok',
        },
        deleteAccountDialog: {
          title: 'Vill du sluta som administratör för {{association}}?',
          text: 'Du kommer bli borttagen som administratör för {{association}}.\nOm du är administratör för fler klubbar kan du fortfarande hantera dem.',
          closeLabel: 'Avbryt',
          confirmLabel: 'Sluta som admin'
        },
        successfulDeleteAccountDialog: {
          title: 'Du har nu avslutat prenumerationen!',
          text: 'Du kommer nu att loggas ut. Om du är prenumerant i en annan förening kan du logga in igen.',
          confirmLabel: 'OK'
        },
        detailsSection: {
          title: 'Inställningar',
          description: 'Här ändrar du dina kontaktuppgifter. Du kan också avsluta din roll som administratör för ett eller flera lag.',
        },
        notificationsSection: {
          closeTitle: 'E-post för avslutat spel',
          closeLabel: 'Ja tack!'
        },
        transcriptSection: {
          title: 'Personuppgiftspolicy',
          text: 'Hur hanteras dina personuppgifter och vad händer med datan om man avsäger sig uppdraget som administratör?',
          linkLabel: 'Personuppgiftspolicy'
        },
        cancelLabel: 'Avbryt',
        saveChangesLabel: 'Spara ändringar',
        deleteAccountLabel: 'Sluta som administratör'
      },
      signup: {
        loginLabel: 'Logga in',
        title: 'Logga in',
        subtitle: 'Här kan du som administratör eller ordförande logga in till Supportern via BankID.',
        listTitle: 'Som administratör för din förening kan du:',
        listItem1: 'Administrera ett eller flera lag i din förening.',
        listItem2: 'Skapa och enkelt administrera olika spel.',
        listItem3: 'Marknadsföra spelen med hjälp av autogenererat marknadsföringsmaterial.',
        listItem4: 'Följa statistik över tid, se mönster och tillsammans sätta upp smarta säljstrategier.',
        listItem5: 'Sätta upp långsiktiga mål för ditt lag och förening.'
      },
      statistics: {
        title: 'Statistik',
        subtitle: 'Med statistiken ser du mönster och får verktyg som hjälper dig att planera och öka försäljningen.',
        periodType: {
          season: 'Säsong',
          year: 'År',
          month: 'Månad',
          week: 'Vecka',
          day: 'Dag'
        },
        reportTypeLabel: 'Välj förening eller lag',
        periodValueLabel: 'Välj tidsperiod',
        totalSumTeamLabel: 'Totalt intjänat till laget',
        totalSumAssociationLabel: 'Totalt utbetalt till föreningen',
        playersLabel: '{{value}} spelare',
        ticketsLabel: '{{value}} Sålda spel',
        chartExplanation: 'Statistiken ovan visar beräknad förtjänst för föreningen baserad på försäljning. Viss avvikelse kan finnas i jämförelse med den faktiska utbetalningen. Skillnaden beror på avrundning som görs i samband med dragning.',
        downloadReportTitle: 'Ladda ner månadsrapporten',
        downloadReportText: 'Välj önskad månad och ladda ner din rapport.',
        downloadReportLabel: 'Välj månad',
        downloadReportButton: 'ladda ner månadsrapporten',
        downloadReportError: 'Fel vid nedladdning av rapporten: {{error}}',
        columnChart: {
          title: 'Biljetter sålda'
        }
      },
      help: {
        pageTitle: 'Hjälp',
        title: 'Handboken ger svar på dina frågor',
        subtitle: 'Här hittar ni svar på hur ni hanterar spel och event, marknadsför samt följer upp resultaten. Hittar ni inte svar på era frågor är ni välkomna att kontakta Svenska Spel.',
      },
      getStarted: {
        title: 'Kom igång'
      },
      communication: {
        title: 'Kommunikation'
      },
      termsAndPrivacy: {
        title: 'Villkor och integritet',
        tabs: {
          personalData: 'Personuppgifter',
          terms: 'Villkor',
          cookies: 'Cookies'
        },
        personalData: {
          title: 'Så här hanterar vi dina personuppgifter',
          subtitle: 'Du ska vara trygg när du anförtror dina personuppgifter till Svenska Spel. Nedan finner du en sammanfattning om hur vi behandlar dessa. I Svenska Spels information om behandling av personuppgifter hittar du en fullständig information i enlighet med dataskyddsförordningens krav.',
          questions: {
            title1: 'Vad är personuppifter?',
            text1: 'Text 1',
            title2: 'Leverans av tjänst',
            text2: 'Text 2',
            title3: 'Question 3',
            text3: 'Text 3',
            title4: 'Question 4',
            text4: 'Text 4',
            title5: 'Question 5',
            text5: 'Text 5',
          }
        },
        terms: {
          title1: 'Grundläggande villkor'
        },
        cookies: {
          title: 'Användning av cookies på Svenska Spels webbplatser',
          infoText: 'Du ska vara trygg när du anförtror dina personuppgifter till Svenska Spel och våra leverantörer. Supportern Admin använder sig endast av förstapartcookies för nödvändiga funktioner – även kallade funktionscookies. Dessa Cookies behövs för att  garantera administrationssidans användbarhet och går därför inte att välja bort.  Du accepterar att Svenska Spel använder funktionscookies genom att fortsätta använda administrationssidan.'
        }
      },
      marketing: {
        title: 'Marknadsföring',
        tabs: {
          materials: 'Marknadsföringsmaterial',
          tips: 'Regler & tips'
        },
        materials: {
          title: 'Ladda ner ditt marknadsföringsmaterial',
          infoText: 'Innan du laddar ner och börjar marknadsföra era spel behöver ni ta del av våra <link></link>.',
          guidelinesLink: 'marknadsföringsriktlinjer',
          listItem1: 'När vi marknadsför Supporternspel pratar vi alltid spel. Vi pratar inte lott eller lotter.',
          listItem2: 'I sociala medier är det viktigt att anpassa kommunikationen efter Metas regelverk kring marknadsföring av spel, som tydligt står beskrivet i <link></link>.',
          handbookLink: 'Handboken',
          highResolutionPDF: {
            title: 'Högupplöst PDF',
            description: 'Här kan du ladda ner en högupplöst och utskriftsbar pdf i A-format.',
            footer: 'Utskriftsbar PDF',
          },
          qrImage: {
            title: 'Bild med QR-kod för aktiv marknadsföring',
            description: 'Här kan du ladda ner en png-bild till ditt bildbibliotek på mobilen eller er surfplatta.',
            footer: 'Aktiv marknadsföring'
          },
          jumbotron: {
            title: 'Mediakub',
            description: '',
            footer: 'Mediakub',
          },
          banners: {
            title: 'Banners',
            description: '',
            footer: 'Banners'
          },
          imageInfoText: 'Bilden är ett exempel med en fiktiv klubb.',
          errorMessage: 'Fel vid nedladdning av materialet',
          acceptTitle: 'Jag har läst och förstått riktlinjerna',
          acceptSubtitle: 'När du bekräftat kan du ladda ner marknadsföringsmaterialet.',
          acceptTermsErrorDialog: {
            errorTitle: 'Bekräftelsefel',
            errorText: "Fel när marknadsföringsvillkoren bekräftades: {{error}}"
          },
          salesScreen: {
            title: 'Försäljning, nedräkning och vinstsumma',
            description: 'En hemsida som erbjuder möjlighet att köpa spel via QR-kod samtidigt som ni visar nedräkning till spelstopp och aktuell vinstsumma. Använd hemsidan på exempelvis mobil, surfplatta eller annan valfri digital skärm.',
            openMatchLinkLabel: 'Hemsida för försäljning - Matchlotteri',
            openEventLinkLabel: 'Hemsida för försäljning - Eventlotteri',
            copyLinkLabel: 'Dela länk till hemsida'
          },
          digitalScreen: {
            title: 'Nedräkning och vinstsumma',
            description: 'En hemsida som erbjuder möjlighet att visa nedräkning till spelstopp och aktuell vinstsumma. Använd hemsidan på exempelvis digitala skärmar på arenan.',
            openMatchLinkLabel: 'Hemsida för digitala skärmar - Matchlotteri',
            openEventLinkLabel: 'Hemsida för digitala skärmar - Eventlotteri',
            copyLinkLabel: 'Dela länk till hemsida'
          },
          winAnimation: {
            title: 'Dragning av vinnare',
            description: 'En hemsida som erbjuder möjligheten att visa dragningen av det aktuella lotteriet på exempelvis digitala skärmar eller mediakub.',
            note: '<warn/> Dragningsupplevelsen kan tidigast visas på på mediakuben 2 minuter efter spelstopp/dragning.',
            warn: 'OBS!',
            openLinkLabel: 'Hemsida för dragning',
            copyLinkLabel: 'Dela länk till hemsida',
            longLabel: 'Länk lång dragning - 90 sekunder',
            mediumLabel: 'Länk mellan dragning - 44 sekunder',
            shortLabel: 'Länk kort dragning - 27 sekunder'
          },
          copyLinkDialog: {
            title: 'Wohoo - länken är kopierad!',
            text: 'Klistra in den i ett SMS, e-post, chatt eller valfritt meddelande för att dela den med andra.'
          }
        },
        tips: {
          title: 'Några enkla och praktiska tips för er marknadsföring',
          subtitle: 'I Sverige ser Spelinspektionen till att spelbranschen är laglig, säker och tillförlitlig. Därför finns ett antal viktiga regler att följa. För att du ska veta vad som gäller och hur du gör rätt har vi samlat ihop några goda råd.',
          inArenaSection: {
            question: 'Får jag marknadsföra Supportern på arenan?',
            answer: `Ja! Använd gärna digitala skärmar, mediakuben, anslagstavlor och så vidare. Föreningens funktionärer kan även gå runt och marknadsföra spelet. Kommunicera gärna vinstsumman och vinnare regelbundet under matchen. Det skapar kännedom och påverkar försäljningen positivt.`
          },
          outOfArenaSection: {
            question: 'Får jag marknadsföra Supportern utanför arenan?',
            answer: 'Ja! Du vet bäst var era anhängare och fans finns. Där kan ni visa upp spelet och låta funktionärer marknadsföra Supportern.'
          },
          emailCommunicationSection: {
            question: 'Får jag kommunicera Supportern via e-post?',
            answer1: 'Ja! I mejlutskick får du berätta om spelet i form av vilka vinstsummorna ni fått in, vad vinstsummorna ska användas till och om engagemanget kring spelet.',
            answer2: 'Du får INTE sälja eller uppmuntra mottagaren till att spela.',
          },
          emailPromotionSection: {
            question: 'Får jag marknadsföra Supportern via e-post?',
            answer: 'Nej! Du får inte skicka ut marknadsföring utan att mottagaren har godkänt att ta emot information om spel.'
          },
          socialMediaPromotionSection: {
            question: 'Får jag marknadsföra Supportern i sociala medier?',
            answer: 'Nej!'
          },
          socialMediaInformationSection: {
            question: 'Får jag berätta om Supportern i sociala medier?',
            answer1: 'Ja! Men kom ihåg att du måste följa reglerna nedan. Gör du inte det finns det en risk att era sociala konton släcks ned av Meta som äger Instagram & Facebook.',
            answer2: 'Du får inte visa Supporterns logotyp.',
            answer3: 'Du får inte kommunicera ordet ”spel”.',
            answer4: 'Du får inte länka inlägget till Svenska Spels kanaler.'
          }
        }
      },
      marketingSales: {
        title: 'Försäljning, nedräkning och vinstsumma på bärbar enhet',
        subtitle: 'Vill du kunna visa nedräkning till spelstopp, aktuell vinstsumma och samtidigt sälja spel på bärbara digitala enheter går du till hemsidan via knappen nedan och visar upp den på mobil eller surfplatta.',
        noSelectedTeamMessage: 'Det finns inget utvalt lag för närvarande.',
        noActiveMatchDrawMessage: 'Det finns ingen aktiv matchlottning för närvarande.',
        noActiveEventDrawMessage: 'Det finns ingen aktiv eventlottning för närvarande.',
        noClosedMatchDrawMessage: 'Det finns ingen sluten matchlottning.',
        noClosedEventDrawMessage: 'Det finns ingen sluten eventlottning.',
        fullScreenLabel: 'Fullskärm',
        design: {
          message: 'Supporta {{teamName}} i vårt<br/> nya matchlotteri!',
          messageEvent: 'Supporta {{teamName}} i vårt<br/> eventlotteri!',
          scanQrCodeMessage: 'Scanna koden med din mobilkamera!',
          footerMessage: 'AB Svenska Spel. Åldersgräns 18 år. Stödlinjen.se',
          winSumTitle: 'Vinstsumma just nu:',
          countdownTitle: 'Spelstopp om:',
          countdownDays: 'dag',
          countdownHours: 'tim',
          countdownMinutes: 'min',
          countdownSeconds: 'sek',
          logoFooterMessage: 'Spelet för både laget och jaget'
        },
        winAnimation: {
          title: 'Vinn animation vid den sista stängda matchen',
          subtitle: 'Animeringen startar från det ögonblick då sidan laddas.',
          almostTimeTitle: 'Snart är det dags ...',
          pickTicketsTitle: 'Plocka fram lotterna!',
          winNumberTitle: 'Vinstnummer:',
          winAmountTitle: 'Vinstsumma just nu:',
          winAmountTitlePast: 'Vinnaren fick:',
          teamNameTitle: '{{teamName}} vinner:'
        }
      },
      marketingDigitalScreens: {
        title: 'Nedräkning och vinstsumma på digtala skärmar',
        subtitle: 'Vill du kunna visa nedräkning till spelstopp och aktuell vinstsumma på digitala skärmar i arenan kan du göra det genom att visa upp hemsidan som nås via knappen nedan.',
      },
      routeNotFound: {
        title: 'Sidan hittas inte',
        description: 'Sidan du letar efter finns inte eller så har du inte behörighet att komma åt den.'
      },
      routeNotAvailable: {
        title: 'Sidan är inte tillgänglig',
        subtitle: 'Inte tillgänglig',
        description: 'Ansökan är inte tillgänglig för tillfället. Du kan försöka igen om några minuter.'
      }
    },
    components: {
      activeDraw: {
        currentJackpot: 'Vinstsumma just nu',
        associationsIncomeLabel: 'Föreningsvinst',
        lowestIncomeLabel: 'Lagsta vinstsumma',
        ticketsSoldMessage: '{{value}} lotter sålda',
        timeUntilNextDrawMessage: '{{value}} till match och dragning',
        marketingMessage: 'Sprid ordet i era digitala och analoga kanaler med autogenererat material.',
        marketingLabel: 'Marknadsför',
        counter: {
          drawingNow: 'Dragning',
          drawingLessThanHour: '{{value}} minuter till dragning',
          drawingToday: 'Dragning idag kl {{value}}',
          drawingTomorrow: 'Dragning imorgon kl {{value}}',
          drawingInFuture: 'Dragning {{date}}, kl {{time}}'
        }
      },
      pendingDraw: {
        pendingLabel: 'Aktiveras',
        title: 'Kommande',
        note: 'Marknadsföringsmaterial blir tillgängligt när lotteriet är aktiverat.',
        marketingLabel: 'Marknadsför',
        associationsIncomeLabel: 'Föreningsvinst',
        playerProfitLabel: 'Spelarvinst',
        matchLabel: 'Matchspel',
        eventLabel: 'Eventspel',
        footerText: 'Makulera',
        deleteDrawDialog: {
          title: 'Är du säker att du vill makulera?',
          closeLabel: 'Avbryt',
          confirmLabel: 'Makulera spel'
        },
        deleteDrawSuccessDialog: {
          title: 'Spelet är makulerat!'
        }
      },
      closedDraw: {
        completedLabel: 'Avslutades',
        ticketPrice: '{{value}}kr/lott',
        players: '{{value}} spelare',
        tickets: '{{value}} lotter',
        associationIncomeLabel: 'Vinstsumma förening',
        winningTicketNumber: 'Vinstnummer',
        playerProfitLabel: 'Spelarvinst',
        matchLabel: 'Matchspel',
        eventLabel: 'Eventspel',
        details: {
          ticketPrice: '{{value}}/spel',
          tickets: '{{value}} st sålda spel',
          players: '{{value}} spelköpare',
          startTime: 'Starttid: {{value}}',
          closingTime: 'Sluttid: {{value}}',
          durationTime: '{{value}}',
          homeMatch: 'Hemmamatch',
          awayMatch: 'Bortamatch',
          createdBy: 'Admin: {{name}}'
        },
        time: {
          startTimeLabel: 'Starttid',
          endTimeLabel: 'Stopptid'
        }
      },
      cancelledDraw: {
        title: 'Avbröts',
        reasonNotDefined: 'Avbokningsorsak har inte lagts till.',
        questionLabel: 'Frågor?'
      },
      drawsSummaryContainer: {
        title: 'Lotterier',
        activeDrawsLabel: 'Pagaende',
        pendingDrawsLabel: 'Kommande',
        completedDrawsLabel: 'Avslutade'
      },
      noTeamMessage: {
        title: 'Inget lag att hantera',
        subtitle: 'För närvarande finns det inga lag inom din förening att hantera. Om du har några frågor, tveka inte att kontakta vårt supportteam. Tack för att du använder vår plattform!'
      },
      appInformation: {
        title: 'Supportern – spelet för både laget och jaget',
        subtitle: 'Det är enkelt att komma igång och smidigt att administrera. Skapa ett spel med några snabba klick.',
        title2: 'Det här är Supportern',
        text2: 'Supportern är en smart tjänst där ni sätter upp digitala spel och samlar in pengar till föreningen. Ni kan skapa och sälja spel både till hemma- och bortamatcher och event. Välj om ni vill lägga upp ett spel till en enskild match, till en hel serie, eller till något event som pågår under längre tid. Varje sålt spel ger en välkommen slant till föreningen.',
        imageNote: 'Exempel på hur det kan se ut för kunden.',
        title3: 'Att bli administratör',
        subtitle3: 'Som administratör för din förening kommer du att:',
        listItem1: 'Administrera ett eller flera lag i din förening.',
        listItem2: 'Skapa och enkelt administrera olika spel.',
        listItem3: 'Marknadsföra spelen bland annat med hjälp av autogenererat marknadsföringsmaterial.',
        listItem4: 'Följa statistik över tid, se mönster och tillsammans sätta upp smarta säljstrategier.',
        listItem5: 'Sätta upp långsiktiga mål för ditt lag och förening.',
        title4: 'Skapa ditt konto',
        text4: 'För att kunna skapa ett konto som administratör behöver du en inbjudan från Ordförande i klubben. Ordförande anmäler vilka administratörer som önskas för klubben och då   skickar Svenska Spel ett sms med länk så att du kan skapa ditt konto och komma igång på ett några minuter. Du har 48 timmar på dig att besvara förfrågan.',
        alreadyCreatedText: 'Har du redan ett administratör- eller ordförandekonto?',
        alreadyCreatedLinkText: 'Logga in här',
      },
      supportContactInfo: {
        support: 'Svenska Spel Partnersupport',
        telephone: 'Telefon: {{phoneNumber}}',
        address: 'Vardagar kl. 07:00–24:00, helger kl. 08:00–24:00',
        sendMail: 'Skicka e-post'
      },
      drawContactSupport: {
        activeDrawMessage: 'Spelet är aktiverat och kan endast ändras av Svenska Spel.',
        supportLink: 'Svenska Spels Partnersupport',
        contactSupport: 'Har du frågor? Kontakta <link></link>'
      }
    },
    header: {
      login: 'Logga in',
      menu: 'Meny',
      title: 'Supportern Admin'
    },
    footer: {
      association: {
        title: 'Föreningen',
        nameLabel: 'Förening',
        organizationalNumberLabel: 'Org.nummer',
        bankNumberLabel: 'Bankkonto',
      },
      bankDetails: {
        title: 'Se alla bankuppgifter',
        bankLabel: 'Bank:',
        clearingNumberLabel: 'Clearing nr:',
        accountNumberLabel: 'Konto nr:'
      },
      riksidrottsLinkText: 'Kontakta <link></link> för att ändra bankuppgifter.',
      riksidrottsLink: 'Riksidrottsförbundet'
    },
    labels: {
      continue: 'Fortsätt',
      ok: 'OK',
      back: 'Tillbaka'
    },
    enums: {
      gender: {
        MALE: 'Herrar',
        FEMALE: 'Damer',
        MIXED: 'Blandad',
      },
      drawType: {
        match: 'Match',
        event: 'Event',
      },
      roles: {
        'CHAIRMAN': 'Ordförande',
        'ADMIN': 'Administratör'
      },
      administratorStatus: {
        CONFIRMED: 'Aktiv',
        PENDING: 'Väntande',
        CANCELED: 'Avböjt',
        EXPIRED: 'Inaktiv'
      }
    },
    currency: {
      integerWithSymbol: '{{value, currency(minimumFractionDigits: 0; currencyDisplay: symbol)}}'
    },
    number: {
      integer: '{{value, number(minimumFractionDigits: 0)}}',
    },
    date: {
      dateLong: '{{value, dateTime(year: numeric; month: short; day: numeric; weekday: short)}}',
      dateShort: '{{value, dateTime(year: numeric; month: short; day: numeric)}}',
      dateTimeShort: '{{value, dateTime(year: numeric; month: short; day: numeric;)}}, kl. {{value, dateTime(hour: 2-digit; minute: 2-digit; hour12: false)}}',
      dateTimeShorter: '{{value, dateTime(month: numeric; day: numeric; hour: 2-digit; minute: 2-digit; hour12: false)}}',
      dateTimeShortest: '{{value, dateTime(month: numeric; day: numeric)}}',
      dateTimeWithDay: '{{value, dateTime(weekday: short; day: numeric; month: short;)}}, kl. {{value, dateTime(hour: numeric; minute: numeric; hour12: false;)}}',
      dateTimeWithDayAndYear: '{{value, dateTime(weekday: short; day: numeric; month: short; year: numeric;)}}, kl. {{value, dateTime(hour: numeric; minute: numeric; hour12: false;)}}',
      dateMonthOnlyShort: '{{value, dateTime(month: short)}}',
      dateDayOnlyShort: '{{value, dateTime(weekday: short)}}',
      weekPrefix: 'v',
      seasonPrefix: 'Säsongen',
      yearPrefix: 'Helåret',
      time: '{{value, dateTime(hour: 2-digit; minute: 2-digit; hour12: false)}}'
    },
    errors: {
      INVITATION_NOT_PENDING: 'Denna inbjudan är inte längre giltig.',
      INVITATION_EXPIRED: 'Denna inbjudan har löpt ut.',
      INPUT_VALIDATION_ERROR: 'Indatavalideringsfel.',
      SALES_START_AFTER_SALES_END: 'Starttid kan inte vara efter försäljningens stopptid.',
      DRAWS_INTERSECTING_VIOLATION: 'Matchspel får inte överlappa med något annat spel för detta lag. Överlappande dragning börjar vid {{salesStart}} och slutar vid {{salesEnd}}. Var vänlig kontrollera din spel/serie, eller dina redan skapade spel under Alla spel.',
      PERMISSION_DENIED_ERROR: 'Åtkomst nekad.',
      ADMINISTRATOR_AGE_LIMIT_VIOLATION: 'Endast personer 18 år och äldre kan läggas till.',
      NUMBER_OF_ADMINISTRATORS_LIMIT_VIOLATION: 'Maximalt antal administratörer har uppnåtts ({{limit}}).',
      DRAW_MINIMUM_DURATION_CODE: 'Dragningstiden får inte vara kortare än 60 minuter. Var vänlig kontrollera tid och datum.',
      ADMINISTRATOR_OPERATOR_ALREADY_EXISTS: 'Den här administratören finns och är redan tilldelad den här föreningen.',
      MAX_EVENT_DRAWS_VIOLATION: 'Maximalt antal överlappande aktiva eller väntande dragningar för detta lag har uppnåtts ({{limit}}).',
      INVALID_MATERIAL_TYPE_VIOLATION: 'Ogiltig materialtyp.',
      TEAM_WITHOUT_ASSOCIATION_VIOLATION: 'Laget är inte relaterat till en förening.',
      MARKETING_TERMS_NOT_CONFIRMED_VIOLATION: 'Villkor för marknadsföringsmaterial har inte bekräftats.',
      SALES_START_IN_PAST: 'Den angivna tiden har redan passerat.',
      MAX_MATCH_SALES_END_UPDATE_DIFF_VIOLATION: 'Sluttiden för dragförsäljning kan flyttas i högst {{limit}} timmar.',
      MAX_EVENT_SALES_END_UPDATE_DIFF_VIOLATION: 'Sluttiden för dragningsförsäljning kan flyttas i högst {{limit}} dagar.',
      SALES_END_IN_PAST: 'Försäljningens slutdatum kan inte infalla i det förflutna.',
      SALES_END_MOVED_TO_PAST_VIOLATION: 'Slutdatum för försäljning kan inte flyttas tillbaka.',
      NOT_FOUND_ERROR: 'Hittades inte.',
      MAX_SALES_END_AFTER_NEXT_DRAW_MIDNIGHT: 'Tiden du har angett krockar med starttiden för nästkommande match. Ändra till en annan tid och försök igen. Om du har fortsatta problem kan du kontakta Svenska Spel Partnersupport',
	    SALES_END_MOVED_TO_PAST_FROZEN_VIOLATION: 'Sluttiden för dragningsförsäljningen kan inte längre flyttas tillbaka eftersom dragningen avslutas om mindre än {{limit}} minuter.',
	    SALES_END_MOVED_TO_FUTURE_FROZEN_VIOLATION: 'Sluttiden för dragningsförsäljningen kan inte längre flyttas fram eftersom dragningen avslutas om mindre än {{limit}} minuter.'
    },
    constraintErrors: {
      NotForbiddenWord: 'Du får inte använda förbjudna ord.',
      NotBlank: 'Inmatningsvärdet får inte vara tomt.'
    },
    dialogs: {
      error: {
        title: 'Oops, något gick fel!',
        closeLabel: 'OK'
      },
      confirmationInitializationError: {
        title: 'Det gick inte att bekräfta inbjudan',
        text: 'Det uppstod ett fel när du bekräftade din inbjudan. Försök att acceptera din inbjudan igen.'
      },
      cookiesDialog: {
        title: 'Vi använder cookies',
        text: 'Supportern Admin använder sig endast av förstapartcookies för nödvändiga funktioner - även kallade funktionscookies. Dessa Cookies behövs för att garantera administrationssidans användbarhet och går därför inte att välja bort. Du accepterar att Svenska Spel använder funktionscookies genom att fortsätta använda administrationssidan.',
        closeLabel: 'Ok'
      },
      drawUpdate: {
        successTitle: 'Uppdateringen lyckades',
        failTitle: 'Uppdateringen misslyckades'
      }
    }
  }
}

export default swedishTranslations
