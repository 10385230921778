import {configureStore} from '@reduxjs/toolkit'

import settingsReducer from '../redux/settings'
import homeReducer from '../pages/home/redux/store'
import signupReducer from '../pages/signup/redux/store'
import appDialogsReducer from '../redux/app-dialogs'
import associationReducer from '../pages/association/redux/store'
import drawsReducer from '../pages/draws/redux/store'
import myAccountReducer from '../pages/my-account/redux/store'
import acceptInvitationReducer from '../pages/accept-invitation/redux/store'
import statisticsReducer from '../pages/statistics/redux/store'
import drawAddReducer from '../pages/draw-add/redux/store'
import administratorsReducer from '../pages/administrators/redux/store'
import administratorsAddReducer from '../pages/administrators-add/redux/store'
import marketingReducer from '../pages/marketing/redux/store'
import confirmInvitationReducer from '../pages/confirm-invitation/redux/store'
import salesReducer from '../pages/sales/redux/store'
import winAnimationReducer from '../pages/win-animation/redux/store'

const SERIALIZABLE_IGNORED_ACTIONS = [
  'appDialogs/hideDialog',
  'appDialogs/removeDialog',
  'appDialogs/addDialog',
  'appDialogs/startDialog',
]

const SERIALIZABLE_IGNORED_PATHS = [
  'appDialogs.dialogs.0.onConfirm',
  'appDialogs.dialogs.0.onCancel',
  'appDialogs.dialogs.0.onConfirmComplete',
  'appDialogs.dialogs.0.onClose',
  'appDialogs.dialogs.0.icon',
]

export const store = configureStore({
  reducer: {
    settings: settingsReducer,
    appDialogs: appDialogsReducer,
    home: homeReducer,
    signup: signupReducer,
    association: associationReducer,
    draws: drawsReducer,
    myAccount: myAccountReducer,
    acceptInvitation: acceptInvitationReducer,
    statistics: statisticsReducer,
    drawAdd: drawAddReducer,
    administrators: administratorsReducer,
    administratorsAdd: administratorsAddReducer,
    marketing: marketingReducer,
    confirmInvitation: confirmInvitationReducer,
    sales: salesReducer,
    winAnimation: winAnimationReducer
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware(
    {
      serializableCheck: {
        ignoredActions: SERIALIZABLE_IGNORED_ACTIONS,
        ignoredPaths: SERIALIZABLE_IGNORED_PATHS,
      },
    },
  ),
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
