import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ErrorObject, ErrorUtils} from "../../../utils/ErrorUtils";
import {RootState} from "../../../store/store";
import {MarketingTeamActiveDrawPublicDataDTO, MarketingTeamPublicDataDTO, teamsApi} from "../../../apis/teams-api";
import {ActiveDrawStatsDTO, drawsApi} from "../../../apis/draws-api";
import moment from "moment";
import {MarketingPageType} from "../../marketing/components/MarketingMaterialsComponent";

interface SalesPageState {
  isLoading: boolean
  loadingError: ErrorObject | null
  teamId: string | null
  teamData: MarketingTeamPublicDataDTO | null
  isLoadingDraw: boolean
  drawLoadingError: ErrorObject | null
  drawData: MarketingTeamActiveDrawPublicDataDTO | null
  drawStats: ActiveDrawStatsDTO | null
  type: MarketingPageType
  isEvent: boolean
}

const initialState: SalesPageState = {
  isLoading: false,
  loadingError: null,
  teamId: null,
  teamData: null,
  isLoadingDraw: false,
  drawLoadingError: null,
  drawData: null,
  drawStats: null,
  type: 'marketing-sales',
  isEvent: false
}

export const fetchTeamData = createAsyncThunk<MarketingTeamPublicDataDTO, void>(
  'sales/fetchData',
  async (payload, thunkApi) => {

    const rootState = thunkApi.getState() as RootState

    return teamsApi.getMarketingTeamPublicData(rootState.sales.teamId!, rootState.sales.isEvent)
  },
)

export const fetchDrawData = createAsyncThunk<[MarketingTeamActiveDrawPublicDataDTO | null, ActiveDrawStatsDTO | null], void>(
  'sales/refreshData',
  async (payload, thunkApi) => {

    const rootState = thunkApi.getState() as RootState

    let drawData: MarketingTeamActiveDrawPublicDataDTO | null = null
    let drawStats: ActiveDrawStatsDTO | null = null

    // If we already have drawData and its drawing time is far into the future, there is no need to reload it
    // because its data does not change
    if (rootState.sales.drawData) {
      const drawingTimeMoment = moment(rootState.sales.drawData.drawingTime)
      const nowMoment = moment()

      if (drawingTimeMoment.isAfter(nowMoment)) {
        const diffDuration = moment.duration(drawingTimeMoment.diff(nowMoment))
        const durationInMinutes = diffDuration.asMinutes()

        // If the drawing is not taking place in more than 2 minutes, use the old drawData
        if (durationInMinutes >= 2) {
          drawData = rootState.sales.drawData
        }
      }
    }

    if (!drawData)
      drawData = await teamsApi.getMarketingTeamActiveDrawPublicData(rootState.sales.teamId!, rootState.sales.isEvent)

    if (drawData && drawData.drawId) {
      const allDrawStats = await drawsApi.getActiveDrawStats([drawData.drawId])

      // We are expecting a single draw stat in the response
      if (allDrawStats && allDrawStats.length)
        drawStats = allDrawStats[0]
    }

    return [drawData, drawStats]
  },
)

export const salesSlice = createSlice({
  name: 'sales',
  initialState,
  reducers: {
    initPage: (state, action: PayloadAction<{teamId: string | null, type: MarketingPageType, isEvent: boolean}>) => {
      state.teamId = action.payload.teamId
      state.type = action.payload.type
      state.isEvent = action.payload.isEvent
      state.teamData = null
      state.drawData = null
      state.drawStats = null
    },
  },
  extraReducers: (builder) => {

    builder.addCase(fetchTeamData.pending, (state) => {
      state.isLoading = true
      state.loadingError = null
    })

    builder.addCase(fetchTeamData.fulfilled, (state, action) => {
      const salesDataDTO = action.payload

      state.isLoading = false
      state.teamData = salesDataDTO
    })

    builder.addCase(fetchTeamData.rejected, (state, action) => {
      state.isLoading = false
      state.loadingError = ErrorUtils.createErrorObjectFromAction(action)
    })

    builder.addCase(fetchDrawData.pending, (state) => {
      state.drawLoadingError = null
      state.isLoadingDraw = true
    })

    builder.addCase(fetchDrawData.fulfilled, (state, action) => {
      const [drawData, drawStats] = action.payload

      state.drawData = drawData
      state.drawStats = drawStats
      state.isLoadingDraw = false
    })

    builder.addCase(fetchDrawData.rejected, (state, action) => {
      state.drawLoadingError = ErrorUtils.createErrorObjectFromAction(action)
      state.isLoadingDraw = false
    })
  },
})

// Actions
export const {initPage} = salesSlice.actions

// Selectors
export const selectIsLoading = (state: RootState) => state.sales.isLoading
export const selectLoadingError = (state: RootState) => state.sales.loadingError
export const selectTeamData = (state: RootState) => state.sales.teamData
export const selectDrawData = (state: RootState) => state.sales.drawData
export const selectDrawStats = (state: RootState) => state.sales.drawStats
export const selectCurrentTeamId = (state: RootState) => state.sales.teamId
export const selectType = (state: RootState) => state.sales.type
export const selectIsEvent = (state: RootState) => state.sales.isEvent

export default salesSlice.reducer
