import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ErrorObject, ErrorUtils} from "../../../utils/ErrorUtils";
import {RootState} from "../../../store/store";
import {
  MarketingTeamLastClosedDrawPublicDataDTO,
  MarketingTeamPublicDataDTO,
  teamsApi
} from "../../../apis/teams-api";

interface WinAnimationPageState {
  isLoading: boolean
  loadingError: ErrorObject | null
  teamId: string | null
  teamData: MarketingTeamPublicDataDTO | null
  isLoadingDraw: boolean
  drawLoadingError: ErrorObject | null
  drawData: MarketingTeamLastClosedDrawPublicDataDTO | null
}

const initialState: WinAnimationPageState = {
  isLoading: false,
  loadingError: null,
  teamId: null,
  teamData: null,
  isLoadingDraw: false,
  drawLoadingError: null,
  drawData: null,
}

export const fetchTeamData = createAsyncThunk<MarketingTeamPublicDataDTO, void>(
  'winAnimation/fetchData',
  async (payload, thunkApi) => {

    const rootState = thunkApi.getState() as RootState

    return teamsApi.getMarketingTeamPublicData(rootState.winAnimation.teamId!, false)
  },
)

export const fetchDrawData = createAsyncThunk<MarketingTeamLastClosedDrawPublicDataDTO, void>(
  'winAnimation/refreshData',
  async (payload, thunkApi) => {

    const rootState = thunkApi.getState() as RootState

    let drawData: MarketingTeamLastClosedDrawPublicDataDTO = await teamsApi.getMarketingTeamLastClosedDrawPublicData(rootState.winAnimation.teamId!)

    return drawData
  },
)

export const winAnimationSlice = createSlice({
  name: 'winAnimation',
  initialState,
  reducers: {
    initPage: (state, action: PayloadAction<{ teamId: string | null }>) => {
      state.teamId = action.payload.teamId
      state.teamData = null
      state.drawData = null
    },
  },
  extraReducers: (builder) => {

    builder.addCase(fetchTeamData.pending, (state) => {
      state.isLoading = true
      state.loadingError = null
    })

    builder.addCase(fetchTeamData.fulfilled, (state, action) => {
      const salesDataDTO = action.payload

      state.isLoading = false
      state.teamData = salesDataDTO
    })

    builder.addCase(fetchTeamData.rejected, (state, action) => {
      state.isLoading = false
      state.loadingError = ErrorUtils.createErrorObjectFromAction(action)
    })

    builder.addCase(fetchDrawData.pending, (state) => {
      state.drawLoadingError = null
      state.isLoadingDraw = true
    })

    builder.addCase(fetchDrawData.fulfilled, (state, action) => {
      state.drawData = action.payload
      state.isLoadingDraw = false
    })

    builder.addCase(fetchDrawData.rejected, (state, action) => {
      state.drawLoadingError = ErrorUtils.createErrorObjectFromAction(action)
      state.isLoadingDraw = false
    })
  },
})

// Actions
export const {initPage} = winAnimationSlice.actions

// Selectors
export const selectIsLoading = (state: RootState) => state.winAnimation.isLoading
export const selectLoadingError = (state: RootState) => state.winAnimation.loadingError
export const selectTeamData = (state: RootState) => state.winAnimation.teamData
export const selectDrawData = (state: RootState) => state.winAnimation.drawData
export const selectCurrentTeamId = (state: RootState) => state.winAnimation.teamId

export default winAnimationSlice.reducer
