import React, {lazy, Suspense, useEffect} from 'react'
import {Route, Routes, useNavigate} from 'react-router'

import {setupResponseInterceptor} from '../apis/core/api-axios'

import {PrivateRoutesWrapper} from './components/PrivateRoutesWrapper'
import {TeamRequiredRouteWrapper} from './components/TeamRequiredRouteWrapper'
import {GuestRoutesWrapper} from './components/GuestRoutesWrapper'
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {RouteNotAvailablePage} from "./components/RouteNotAvailablePage";
import {RouteNotFoundPage} from "./components/RouteNotFoundPage";
import {selectIsChairman} from "../redux/settings";

const HomePageLazy = lazy(() => import('../pages/home/HomePage'))
const SignupPageLazy = lazy(() => import('../pages/signup/SignupPage'))
const AssociationPageLazy = lazy(() => import('../pages/association/AssociationPage'))
const AdministratorsPageLazy = lazy(() => import('../pages/administrators/AdministratorsPage'))
const AdministratorsAddPageLazy = lazy(() => import('../pages/administrators-add/AdministratorsAddPage'))
const DrawsPageLazy = lazy(() => import('../pages/draws/DrawsPage'))
const MyAccountPageLazy = lazy(() => import('../pages/my-account/MyAccountPage'))
const AcceptInvitationPageLazy = lazy(() => import('../pages/accept-invitation/AcceptInvitationPage'))
const GetStartedPageLazy = lazy(() => import('../pages/get-started/GetStartedPage'))
const CommunicationPageLazy = lazy(() => import('../pages/communication/CommunicationPage'))
const HelpPageLazy = lazy(() => import('../pages/help/HelpPage'))
const TermsAndPrivacyPageLazy = lazy(() => import('../pages/terms-and-privacy/TermsAndPrivacyPage'))
const StatisticsPageLazy = lazy(() => import('../pages/statistics/StatisticsPage'))
const DrawAddPageLazy = lazy(() => import('../pages/draw-add/DrawAddPage'))
const MarketingPageLazy = lazy(() => import('../pages/marketing/MarketingPage'))
const ConfirmInvitationPageLazy = lazy(() => import('../pages/confirm-invitation/ConfirmInvitationPage'))
const SalesPageLazy = lazy(() => import('../pages/sales/SalesPage'))
const WinAnimationPageLazy = lazy(() => import('../pages/win-animation/WinAnimationPage'))

export const AppRoutesComponent = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const isCurrentUserChairman = useAppSelector(selectIsChairman)

  useEffect(() => {
    // We are using this component to initiate axios interceptor with navigate function
    // because we need to call `useNavigate` within a context of a router
    setupResponseInterceptor(navigate, dispatch)
  }, [navigate, dispatch])

  return (
    <Suspense fallback={<SuspenseFallback/>}>
      <Routes>
        <Route element={<PrivateRoutesWrapper/>}>
          <Route element={<TeamRequiredRouteWrapper/>}>
            <Route path="/" element={<HomePageLazy/>}/>
            <Route path="/association" element={<AssociationPageLazy/>}/>
            <Route path="/draws" element={<DrawsPageLazy/>}/>
            <Route path="/draws/add" element={<DrawAddPageLazy/>}/>
            <Route path='/settings' element={<MyAccountPageLazy/>}/>
            <Route path='/statistics' element={<StatisticsPageLazy/>}/>
            <Route path='/marketing' element={<MarketingPageLazy/>}/>
            {isCurrentUserChairman && (
              <>
                <Route path='/administrators' element={<AdministratorsPageLazy/>}/>
                <Route path='/administrators/add' element={<AdministratorsAddPageLazy/>}/>
                <Route path='/administrators/:id' element={<AdministratorsAddPageLazy/>}/>
              </>
            )}
          </Route>
          <Route path='/confirm-invitation' element={<ConfirmInvitationPageLazy/>}/>
        </Route>
        <Route element={<GuestRoutesWrapper/>}>
          <Route path="/signup" element={<SignupPageLazy/>}/>
        </Route>
        <Route path='/get-started' element={<GetStartedPageLazy/>}/>
        <Route path='/communication' element={<CommunicationPageLazy/>}/>
        <Route path='/help' element={<HelpPageLazy/>}/>
        <Route path='/terms-and-privacy' element={<TermsAndPrivacyPageLazy/>}/>
        <Route path='/accept-invitation' element={<AcceptInvitationPageLazy/>}/>
        <Route path='/not-available' element={<RouteNotAvailablePage/>}/>
        <Route path='/marketing-sales/:teamId' element={<SalesPageLazy/>}/>
        <Route path='/marketing-digital-screens/:teamId' element={<SalesPageLazy/>}/>
        <Route path='/marketing-win/:teamId' element={<WinAnimationPageLazy/>}/>
        <Route path='*' element={<RouteNotFoundPage/>}/>
      </Routes>
    </Suspense>
  )
}

const SuspenseFallback = () => {
  return <div></div>
}

