import React, {useCallback, useEffect, useRef} from 'react';
import {BrowserRouter, useLocation} from 'react-router-dom'
import './App.css';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from "./store/hooks";
import {
  initApp,
  selectHasAtLeastOneTeam, selectIsFrozenScreenRoute, selectIsFullScreenRoute,
  selectIsInitialized,
  selectIsInitializing,
  selectIsLoggedIn,
  setCurrentRoute,
  setDialogQueueEmpty
} from "./redux/settings";
import {AppHeaderComponent} from "./components/app-header/AppHeaderComponent";
import {AppRoutesComponent} from "./routing/AppRoutesComponent";
import {AppDialogsComponent} from "./components/app-dialogs/AppDialogsComponent";
import clsx from "clsx";
import {AppMenu} from "./components/app-menu/app-menu";
import {UserMenu} from "./components/user-menu/user-menu";
import {ScrollUtils} from "./utils/ScrollUtils";
import {AppBannerComponent} from './components/app-banner/AppBannerComponent';
import {ErrorMessageComponent} from './components/error-message/ErrorMessageComponent';
import moment from "moment";
import {LocalStorageUtils} from './utils/LocalStorageUtils';
import {Dialog, DialogUtils} from './utils/DialogUtils';

// Setup moment start day of week and start week of year, regardless of locale we want Monday to be the first day.
// Statistics page calculations depend on the fact that January 1st is in the first week of the year (which is not
// necessarily true for some locales).
const momentLocaleUpdate = {
  week: {
    // First day of week should be Monday
    dow: 1,
    // January 1sh should belong to the first week of the year
    doy: 7
  }
}

moment.updateLocale(moment.locale(), momentLocaleUpdate);

moment.locales().forEach(function (locale) {
  moment.updateLocale(locale, momentLocaleUpdate);
});

function App() {
  const dispatch = useAppDispatch()
  const isRenderedRef = useRef(false)
  const isInitializing = useAppSelector(selectIsInitializing)
  const isInitialized = useAppSelector(selectIsInitialized)
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const hasAtLeastOneTeam = useAppSelector(selectHasAtLeastOneTeam)
  const isFrozenScreen = useAppSelector(selectIsFrozenScreenRoute)
  const isFullScreen = useAppSelector(selectIsFullScreenRoute)

  /**
   * Initialize app considering if it has already been initialized.
   */
  useEffect(() => {
    if (!isInitializing && !isInitialized && !isRenderedRef.current) {
      isRenderedRef.current = true

      dispatch(initApp())
    }
  }, [dispatch, isInitializing, isInitialized, isRenderedRef])

  if (!isInitialized)
    return null

  return (
    <div className="App">
      <ErrorMessageComponent/>
      <BrowserRouter basename={process.env.PUBLIC_URL + '/'}>
        <AppMenu/>
        <UserMenu/>
        <div>
          <LocalMainContent>
            {isLoggedIn && hasAtLeastOneTeam && !isFrozenScreen && <AppBannerComponent/>}
            {!isFullScreen && <AppHeaderComponent/>}
            <AppRoutesComponent/>
          </LocalMainContent>
        </div>
        <AppDialogsComponent/>
      </BrowserRouter>
    </div>
  );
}

export default App;

type LocalMainContentProps = {
  children: React.ReactNode
}

const LocalMainContent = ({children}: LocalMainContentProps) => {

  const dispatch = useAppDispatch()
  const ref = useRef<HTMLElement | null>(null)
  const location = useLocation()
  const isFullScreen = useAppSelector(selectIsFullScreenRoute)
  const cookieDialogRef = useRef<Dialog | null>(null)
  const {t} = useTranslation()

  const displayCookiesDialog = useCallback(() => {
    cookieDialogRef.current = DialogUtils.showNotificationDialog({
      title: t('dialogs.cookiesDialog.title'),
      text: t('dialogs.cookiesDialog.text'),
      closeLabel: t('dialogs.cookiesDialog.closeLabel'),
      icon: 'gdpr-cookies',
      onClose: () => {
        dispatch(setDialogQueueEmpty(true))
      }
    })

    dispatch(setDialogQueueEmpty(false))
  }, [t, dispatch])

  useEffect(() => {
    ScrollUtils.scrollingContainer = ref.current
  }, [ref])

  /**
   * Update current route in settings whenever route changes.
   */
  useEffect(() => {
    dispatch(setCurrentRoute(location.pathname))
  }, [dispatch, location])

  /**
   * Display cookies dialog if it has not already been displayed.
   */
  useEffect(() => {

    if (!cookieDialogRef.current && !LocalStorageUtils.getCookiesInfoSeen()) {

      const currentRoute = location.pathname
      const ignoreCookiesDialogRoutes = ['/marketing-sales', '/marketing-digital-screens', '/marketing-win']

      const shouldIgnoreCookiesDialog = ignoreCookiesDialogRoutes.filter(it => currentRoute && currentRoute.startsWith(it)).length > 0

      if(!shouldIgnoreCookiesDialog) {
        LocalStorageUtils.saveCookiesInfoSeen()
        displayCookiesDialog()
      }
    }
  }, [displayCookiesDialog, location])

  return (
    <main id="scrollable-container" className={clsx(
      'w-full flex flex-col text-navy ',
      !isFullScreen && 'pt-11 xl:pt-[60px]'
    )} ref={ref}>{children}
    </main>
  )
}
