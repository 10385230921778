import {ApiBase, PageResponse} from './core/api-base'
import {DrawStatus, DrawType, Price, UserActionDTO} from "../utils/AssociationTypes";
import {AssociationDTO} from "./associations-api";
import {TeamDTO} from "./teams-api";
import {ErrorUtils} from "../utils/ErrorUtils";
import {DialogUtils} from "../utils/DialogUtils";
import i18n from "i18next";
import {stringify} from "query-string";
import {rootApiAxios} from "./core/api-axios";


export type DrawCancellationDTO = {
  time: string
  reason: string
}

export interface DrawStatsDTO {
  totalTickets?: number
  totalPlayers?: number
  totalRows?: number
  poolSize?: Price
  associationIncome?: Price
}

export interface DrawWinnerDataDTO {
  number?: string
}

export type DrawWinner = {
  ticketId: string
  winnerData: DrawWinnerDataDTO
}

export type DrawDrawPrizeCategory = {
  id?: string
  prizeAmount: Price
  totalShares: number
  totalPrizeAmount: Price
  winners: DrawWinner[]
}

export type DrawResultInfo = {
  stats: DrawStatsDTO
  prizeCategories: DrawDrawPrizeCategory[]
}

export interface DrawDTO {
  id: string
  name: string
  salesStart: string
  salesEnd: string
  drawingTime: string
  price: Price
  type: DrawType
  isHomeGame: boolean
  association: AssociationDTO
  team: TeamDTO
  opponentTeam?: TeamDTO
  status: DrawStatus
  objective?: string
  created: UserActionDTO
  cancelled?: DrawCancellationDTO
  updateReason?: string
  results?: DrawResultInfo
  updated?: UserActionDTO
  originalSalesEnd: string
}

export type NewEventDrawDTO = Pick<DrawDTO, 'name' | 'price' | 'salesStart' | 'salesEnd' | 'type' | 'team' | 'objective' | 'status'>
export type NewMatchDrawDTO = Pick<DrawDTO, 'name' | 'price' | 'salesStart' | 'salesEnd' | 'type' | 'team' | 'opponentTeam' | 'status' | 'isHomeGame'>

export type DrawUpdateDTO = {
  salesStart?: string
  salesEnd?: string
}

export type DrawSortField = 'salesStart' | 'salesEnd' | "soldTickets" | "jackpotSize"
export type DrawSortDirection = 'ASC' | 'DESC'

export type ActiveDrawStatsDTO = {
  id: string
  stats: ActiveDrawStatsStatsDTO
}

export type ActiveDrawStatsStatsDTO = {
  poolSize: Price
  totalSales: Price
  totalTickets: number
  totalRows: number
  totalPlayers: number
}

const USE_MOCK = false

class DrawsApi extends ApiBase {

  async getDraws(
    pageIndex: number,
    pageSize: number,
    queryParams: {
      teamId?: string,
      status?: DrawStatus,
      sortBy?: DrawSortField,
      sortDirection?: DrawSortDirection,
      type?: DrawType,
      teamInfo?: boolean
    }): Promise<PageResponse<DrawDTO>> {
    return this.$getPaged<DrawDTO>('/draws', pageIndex, pageSize, queryParams)
  }

  async getDraw(drawId: string): Promise<DrawDTO> {
    return this.$getSingle<DrawDTO>('/draws/' + drawId)
  }

  addDraw(dto: NewEventDrawDTO | NewMatchDrawDTO): Promise<string> {
    return this.$post<NewEventDrawDTO | NewMatchDrawDTO>('/draws', dto)
  }

  deleteDraw(drawId: string): Promise<void> {
    return this.$delete(`/draws/${drawId}`)
  }

  async updateDrawWithNotifications(drawId: string, salesStart?: string, salesEnd?: string): Promise<void> {
    const dto: DrawUpdateDTO = {
      salesStart,
      salesEnd
    }

    try {

      await this.$put<DrawUpdateDTO>(`/draws/${drawId}`, dto)

      DialogUtils.showNotificationDialog({
        title: i18n.t('dialogs.drawUpdate.successTitle'),
        text: undefined,
        icon: 'thumbs-up',
        closeLabel: i18n.t('labels.ok')
      })

    } catch (error) {

      const errorObject = ErrorUtils.createErrorObject(error)
      const errorMessage = ErrorUtils.getErrorMessage(errorObject)
      DialogUtils.showNotificationDialog({
        title: i18n.t('dialogs.drawUpdate.failTitle'),
        text: errorMessage,
        icon: 'exclamation-sign',
        closeLabel: i18n.t('labels.ok'),
        errorMode: true
      })

      throw error
    }
  }

  async getActiveDrawStats(drawId: string[]): Promise<ActiveDrawStatsDTO[]> {
    if(USE_MOCK) {

      const result: ActiveDrawStatsDTO[] = [
        {
          id: '123',
          stats: {
            poolSize: {amount: 1000, currency: 'SEK'},
            totalPlayers: 1230,
            totalRows: 312,
            totalSales: {amount: 1750, currency: 'SEK'},
            totalTickets: 250
          }
        }
      ]

      return Promise.resolve(result)

    } else {
      const queryString = stringify({drawId})
      const url = '/raffle-pooled/web/v1/stats/draws/active' + (queryString ? `?${queryString}` : '')

      const {data} = await rootApiAxios.get(url, await this.getConfig())

      return data
    }
  }

}

export const drawsApi = new DrawsApi()
