import {ApiBase, PageResponse} from './core/api-base'
import {CategoryDTO} from "./categories-api";
import {SportDTO} from "./sports-api";
import {AssociationDTO} from "./associations-api";
import {Gender, Price, UserActionDTO} from "../utils/AssociationTypes";
import moment from "moment";

export interface TeamBrandingDTO {
  logo?: string
  background?: string
  color?: string
}

export interface TeamDTO {
  id: string
  name: string
  shortName?: string
  description?: string
  code?: string
  gender: Gender
  association?: AssociationDTO
  sport: SportDTO
  category: CategoryDTO
  branding?: TeamBrandingDTO
  startMonth: number
  created?: UserActionDTO
  updated?: UserActionDTO
}

export type MarketingMaterialsType = 'print_lg_pdf' | 'print_md_img'

export type MarketingTeamPublicDataDTO = {
  teamId: string
  teamName: string
  associationId: string
  associationName: string
  backgroundColor: string
  foregroundColor: string
  buyUrl: string
  backgroundImageUrl: string
  logoUrl: string
  hasNoAssociation: boolean
}

export type MarketingTeamActiveDrawPublicDataDTO = {
  drawId: string
  price: Price
  winAmount: Price
  salesStartTime: string
  salesEndTime: string
  drawingTime: string
  hasNoDraw: boolean
}

export type MarketingTeamLastClosedDrawPublicDataDTO = {
  drawId: string
  price: Price
  winAmount: Price
  associationAmount: Price
  salesStartTime: string
  salesEndTime: string
  drawingTime: string
  hasNoDraw: boolean
  winningNumber: string
}

/**
 * Useful for testing marketing pages without real backend.
 */
const USE_MOCKS = false

function currentTimestamp() {
  return new Date().getTime()
}

class TeamsApi extends ApiBase {

  async getTeams(pageIndex: number, pageSize: number, associationId: string, addDrawInfo = false): Promise<PageResponse<TeamDTO>> {
    return this.$getPaged<TeamDTO>('/teams', pageIndex, pageSize, {associationId, drawInfo: addDrawInfo})
  }

  async getTeam(userId: string): Promise<TeamDTO> {
    return this.$getSingle('/teams/' + userId)
  }

  async searchAll(searchParameters: Record<string, string | number | boolean>): Promise<TeamDTO[]> {
    const response = await this.$getPaged<TeamDTO>(`/teams`, 0, 10_000, searchParameters)
    return response.items
  }

  async getNumberOfFavoritesPlayers(teamId: string): Promise<number> {
    return this.$getSingle('/teams/' + teamId + '/favorites-count')
  }

  async downloadMaterials(teamId: string, materialsType: MarketingMaterialsType): Promise<void> {
    return this.$download('/teams/' + teamId + '/materials/' + materialsType)
  }

  async getMarketingTeamPublicData(teamId: string, isEvent: boolean): Promise<MarketingTeamPublicDataDTO> {
    if (USE_MOCKS)
      return TEAM_PUBLIC_DATA
    else
      return this.$getSingle('/teams/' + teamId + '/materials-team-data', {event: isEvent, timestamp: currentTimestamp()})
  }

  async getMarketingTeamActiveDrawPublicData(teamId: string, isEvent: boolean): Promise<MarketingTeamActiveDrawPublicDataDTO> {
    if (USE_MOCKS)
      return TEAM_ACTIVE_DRAW_PUBLIC_DATA
    else
      return this.$getSingle('/teams/' + teamId + '/materials-active-draw-data', {event: isEvent, timestamp: currentTimestamp()})
  }

  async getMarketingTeamLastClosedDrawPublicData(teamId: string): Promise<MarketingTeamLastClosedDrawPublicDataDTO> {
    if (USE_MOCKS)
      return MOCK_TEAM_LAST_CLOSED_DRAW_PUBLIC_DATA
    else
      return this.$getSingle('/teams/' + teamId + '/materials-last-closed-draw-data', {timestamp: currentTimestamp()})
  }

}

export const teamsApi = new TeamsApi()

const MOCK_TEAM_LAST_CLOSED_DRAW_PUBLIC_DATA = {
  winningNumber: '1234',
  drawId: '123',
  drawingTime: moment().subtract(2, 'days').toISOString(),
  winAmount: {amount: 50000, currency: 'SEK'},
  price: {amount: 123, currency: 'EUR'},
  salesStartTime: moment().subtract(4, 'days').toISOString(),
  salesEndTime: moment().subtract(3, 'days').toISOString(),
  hasNoDraw: false,
  associationAmount: {amount: 25000, currency: 'SEK'}
}

const TEAM_ACTIVE_DRAW_PUBLIC_DATA = {
  drawId: '123',
  hasNoDraw: false,
  drawingTime: moment().subtract(2, 'days').toISOString(),
  price: {amount: 123, currency: 'EUR'},
  salesStartTime: moment().subtract(4, 'days').toISOString(),
  salesEndTime: moment().subtract(3, 'days').toISOString(),
  winAmount: {amount: 50000, currency: 'EUR'}
}

const TEAM_PUBLIC_DATA = {
  "teamId": "628207929034e11273e91aa8",
  "teamName": "Malmö Redhawks Herrar",
  "associationId": "628204e89034e11273e91a9a",
  "associationName": "Malmö Redhawks IF",
  "backgroundColor": "#ED1C24",
  "foregroundColor": "#85ed1c",
  "buyUrl": "https://www.svenskaspel.se/supportern/alla-lag/628207929034e11273e91aa8?utm_source=dynamisk&amp;utm_medium=qr&amp;utm_campaign=sup_supportern-36_72301&amp;utm_content=#matchspel",
  "backgroundImageUrl": "https://ss-uat.bwloto.xyz/web/images/admin/c1923a6c-9b67-4beb-8891-968b3fa557bf.jpeg",
  "logoUrl": "https://ss-uat.bwloto.xyz/web/images/admin/df32e0e8-3cb4-4a42-8023-46f2729d91cd.png",
  "hasNoAssociation": false
}
